import React, { useState, useRef } from 'react'; 
import HTMLFlipBook from 'react-pageflip';

const Magazine2024 = () => { 
    const book = useRef();
    const [target, setTarget] = useState(null);
    const [zoom, setZoom] = useState(1);  // State to manage zoom level

    const handleZoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom + 0.1, 2));  // Increase zoom level, max 2x
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 1));  // Decrease zoom level, min 1x
    };

    return (
        <>
            <div className={`flipOpen activebook`}>
               

                {/* Zoom controls */}
                <div className='buttonDesign'>
                <button onClick={() =>
                    book.current.pageFlip().flipPrev()}>Previous</button>
                <button onClick={() =>
                    book.current.pageFlip().flipNext()}>Next</button>
                    <button onClick={handleZoomIn}><i class="bi bi-zoom-in"></i></button>
                    <button onClick={handleZoomOut}><i class="bi bi-zoom-out"></i></button>
                </div>

                <HTMLFlipBook
                    width={500}
                    height={680}
                    minWidth={315}
                    maxWidth={1000}
                    minHeight={400}
                    maxHeight={1200}
                    ref={book}
                    mobileScrollSupport={true}
                    showCover={true}
                    useMouseEvents={true}
                    className="flipPageComponent"
                    onChange={(e) => setTarget(e)}
                    pageBackground="rgb(255, 255, 255)"
                    uncutPages="true"
                >
                    {/* Image content */}
                    <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-1.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-2.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-3.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-4.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-5.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-6.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-7.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-8.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-9.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-10.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-11.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-12.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-13.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-14.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-15.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-16.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-17.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-19.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-20.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-21.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-22.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-23.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-24.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-25.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-26.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-27.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-28.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-29.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-30.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-31.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-32.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-34.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-35.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-36.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-37.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-38.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-39.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-40.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-41.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-42.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-43.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-44.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-45.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-46.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-47.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-48.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-49.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-50.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-51.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-52.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-53.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-54.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-55.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-56.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-57.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-58.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-59.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-60.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-61.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-62.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-63.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-64.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-65.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-66.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-67.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-68.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-69.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-70.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-71.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-72.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-73.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-74.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-75.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-76.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-77.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-78.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-79.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-80.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-81.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-82.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-83.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-84.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-86.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-87.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-88.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-89.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-90.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-91.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-92.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-93.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-94.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-95.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-96.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-97.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-98.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-99.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-100.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-101.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-102.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-103.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-104.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-105.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-106.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-107.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-108.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-109.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-110.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-111.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-112.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-113.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-114.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-115.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-116.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-117.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-118.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-119.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-120.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-121.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-122.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-123.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-124.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-125.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-126.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-127.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-128.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-129.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-130.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-131.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-132.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-133.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-134.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-135.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-136.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-137.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-138.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-139.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-140.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-141.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-142.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-143.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-144.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-145.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-146.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-147.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-148.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-149.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-150.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-151.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-152.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-153.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-154.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-155.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-156.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-157.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-158.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-159.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-160.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-161.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-162.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-163.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-164.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-165.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-166.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-167.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine24-168.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                     
                    {/* Add the rest of the images similarly */}
                </HTMLFlipBook>
            </div>
        </>
    );
}

export default Magazine2024;

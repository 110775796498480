import React, { useState, useRef } from 'react'; 
import HTMLFlipBook from 'react-pageflip';

const Magazine2023 = () => { 
    const book = useRef();
    const [target, setTarget] = useState(null);
    const [zoom, setZoom] = useState(1);  // State to manage zoom level

    const handleZoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom + 0.1, 2));  // Increase zoom level, max 2x
    };

    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 1));  // Decrease zoom level, min 1x
    };

    return (
        <>
            <div className={`flipOpen activebook`}>
               

                {/* Zoom controls */}
                <div className='buttonDesign'>
                <button onClick={() =>
                    book.current.pageFlip().flipPrev()}>Previous</button>
                <button onClick={() =>
                    book.current.pageFlip().flipNext()}>Next</button>
                    <button onClick={handleZoomIn}><i class="bi bi-zoom-in"></i></button>
                    <button onClick={handleZoomOut}><i class="bi bi-zoom-out"></i></button>
                </div>

                <HTMLFlipBook
                    width={500}
                    height={680}
                    minWidth={315}
                    maxWidth={1000}
                    minHeight={400}
                    maxHeight={1200}
                    ref={book}
                    mobileScrollSupport={true}
                    showCover={true}
                    useMouseEvents={true}
                    className="flipPageComponent"
                    onChange={(e) => setTarget(e)}
                    pageBackground="rgb(255, 255, 255)"
                    uncutPages="true"
                >
                    {/* Image content */}
                    <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-c1.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-c2.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-c3.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-c4.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                    <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-1.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-2.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-3.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-4.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-5.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-6.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-7.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-8.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-9.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-10.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-11.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-12.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-13.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-14.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-15.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-16.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-17.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-19.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-20.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-21.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-22.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-23.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-24.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-25.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-26.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-27.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-28.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-29.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-30.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-31.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-32.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-34.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-35.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-36.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-37.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-38.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-39.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-40.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-41.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-42.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-43.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-44.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-45.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-46.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-47.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-48.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-49.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-50.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-51.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-52.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-53.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-54.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-55.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-56.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-57.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-58.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-59.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-60.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-61.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-62.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-63.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-64.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-65.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-66.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-67.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-68.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-69.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-70.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-71.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-72.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-73.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-74.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-75.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-76.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-77.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-78.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-79.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-80.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-81.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-82.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-83.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-84.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-86.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-87.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-88.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-89.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-90.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-91.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-92.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-93.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-94.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-95.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-96.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-97.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-98.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-99.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-100.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-101.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-102.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-103.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-104.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-105.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-106.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-107.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-108.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-109.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-110.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-111.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-112.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-113.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-114.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-115.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-116.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-117.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-118.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-119.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-120.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-121.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-122.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-123.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-124.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-125.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-126.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-127.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-128.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-129.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-130.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-131.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-132.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-133.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-134.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-135.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-136.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-137.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-138.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-139.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                        <div className="page"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/magazine/magazine23-140.jpg" alt="St. mary Mirzapur" style={{ transform: `scale(${zoom})`, transition: 'transform 0.2s ease' }}  /></div>
                       
                     
                    {/* Add the rest of the images similarly */}
                </HTMLFlipBook>
            </div>
        </>
    );
}

export default Magazine2023;

import React from "react";
import { Link } from 'react-router-dom';
import Copyright from "./Copyright";

const Footer = () => {

    return(
        <>
        <footer>
            <div className="footer">
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-lg-3 col-md-6">
                            <div className="quick-link">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li><Link to='/about'>About us</Link></li>
                                    <li><Link to='/syllabus'>Syllabus</Link></li>
                                    <li><a href="/PrincipalMessage">Principal's Desk</a></li>
                                    <li><a href="/RulesRegulations">Rules of Discipline</a></li>
                                    <li><a href="/Gallery">Photo Gallery</a></li>
                                    <li><a href="/faculty">Faculty</a></li> 
                                    <li><a href="/TC">TC</a></li>
                                    <li><a href="">Sitemap</a></li>
                                    <li><a href="/ERPSoftware">ERP Software</a></li>
                                    <li><a href="/ParentPortal">Parent Portal</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3  col-md-6">
                            <div className="cont-dtl">
                                <h3>contact us</h3>
                                <p><i class="bi bi-geo-alt"></i> <span> Station Road, Pilikothi, Mirzapur, Pin Code: 231001, Uttar Pradesh, INDIA</span></p>
                                <p><i class="bi bi-telephone-fill"></i> <span> <a href="tel: 05442 350050"> 05442 350050</a>
                                <a href="tel: 05442 350051"> 05442 350051</a>
                                <a href="tel: 05442 350052"> 05442 350052</a>
                                </span></p>
                                <p><i class="bi bi-envelope"></i><span> <a href="mailto: stmarymzp1@rediffmail.com">stmarymzp1@rediffmail.com</a> 
                                </span></p>
                                 
                            </div>
                        </div>
                        
                        <div className="col-lg-3 col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.027871809532!2d82.57586871498955!3d25.13474854039938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398fc030b74246c7%3A0x6e2d40ad348ba6ff!2sSt.%20Mary's%20School%20%26%20College!5e0!3m2!1sen!2sin!4v1602918258631!5m2!1sen!2sin" width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="d-flex">
                                <div className="app-cont">
                                    <p>Download Mobile App<br></br> A Common Platform For Educators, Parents & Teachers</p>
                                    <div>
                                    <a href='https://apps.apple.com/in/app/campuscare-10x/id1611283814' target='_blank'> 
                                    <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/app1.png" /></a>
                            <a href='https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN' target='_blank'> 
                            <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/app2.png" /></a>
                                    </div>
                                    <p className="link">
                                        <a target="_blank" href="https://smm.campuscare.info/">smm.campuscare.info  </a>
                                    </p>
                                </div>
                                <div className="phone-img">
                                    <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/app3.png" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            
            </footer>
            <Copyright />
        </>
    )
}
export default Footer;
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route,  Routes } from 'react-router-dom';

// import Home from './Pages/home'
import Home from './Pages/home'; 
import Gallery from './Pages/Gallery'; 
import SubGallery from './Pages/SubGallery'; 
import VideoGallery from './Pages/VideoGallery'; 
import Events from './Pages/Events'; 
import News from './Pages/News'; 
import About from './Pages/about'; 
import HistoryAim from './Pages/history-aim'; 
import PrincipalMessage from './Pages/PrincipalMessage'; 
import OurPrincipal from './Pages/ourPrincipal'; 
import ComingSoon from './Pages/comingSoon'; 
import RulesRegulations from './Pages/RulesRegulations'; 
import AdmissionProcedure from './Pages/admissionProcedure'; 
import SchoolUniform from './Pages/schoolUniform'; 
import Curriculum from './Pages/curriculum'; 
import RoleOfHonour from './Pages/roleOfHonour'; 
import FormerFaculty from './Pages/formerFaculty'; 
import CampusTour from './Pages/campusTour'; 
import ManagementCommittee from './Pages/management-comiitee'; 
import Faculty from './Pages/faculty'; 
import Syllabus from './Pages/syllabus'; 
import Laboratory from './Pages/laboratory'; 
import Library from './Pages/library'; 
import Sports from './Pages/sports'; 
import TopperX from './Pages/topperX'; 
import TopperXII from './Pages/topperXII'; 
import SchoolTopper from './Pages/school-topper';
import ClassTopper from './Pages/classTopper';
import VoiceSMS from './Pages/voiceSMS';
import Alumni from './Pages/alumni';
import ContactUs from './Pages/contactUs';
import TC from './Pages/TC';
import ParentPortal from './Pages/ParentPortal';
import ERPSoftware from './Pages/ERPSoftware';
import Magazine from './Pages/magazine';
import Magazine2024 from './Pages/Magazine2024';
import Magazine2023 from './Pages/Magazine2023';

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} /> 
          <Route exact path="/Gallery" element={<Gallery />} /> 
          <Route exact path="/SubGallery" element={<SubGallery />} /> 
          <Route exact path="/VideoGallery" element={<VideoGallery />} /> 
          <Route exact path="/Events" element={<Events />} /> 
          <Route exact path="/News" element={<News />} /> 
          <Route exact path="/about" element={<About />} /> 
          <Route exact path="/history-aim" element={<HistoryAim />} /> 
          <Route exact path="/PrincipalMessage" element={<PrincipalMessage />} /> 
          <Route exact path="/ourPrincipal" element={<OurPrincipal />} /> 
          <Route exact path="/comingSoon" element={<ComingSoon />} /> 
          <Route exact path="/RulesRegulations" element={<RulesRegulations />} /> 
          <Route exact path="/admissionProcedure" element={<AdmissionProcedure />} /> 
          <Route exact path="/schoolUniform" element={<SchoolUniform />} /> 
          <Route exact path="/curriculum" element={<Curriculum />} /> 
          <Route exact path="/roleOfHonour" element={<RoleOfHonour />} /> 
          <Route exact path="/formerFaculty" element={<FormerFaculty />} /> 
          <Route exact path="/campusTour" element={<CampusTour />} /> 
          <Route exact path="/management-committee" element={<ManagementCommittee />} /> 
          <Route exact path="/faculty" element={<Faculty />} /> 
          <Route exact path="/syllabus" element={<Syllabus />} /> 
          <Route exact path="/laboratory" element={<Laboratory />} /> 
          <Route exact path="/library" element={<Library />} /> 
          <Route exact path="/sports" element={<Sports />} /> 
          <Route exact path="/topperX" element={<TopperX />} /> 
          <Route exact path="/topperXII" element={<TopperXII />} /> 
          <Route exact path="/school-topper" element={<SchoolTopper />} /> 
          <Route exact path="/classTopper" element={<ClassTopper />} /> 
          <Route exact path="/voiceSMS" element={<VoiceSMS />} /> 
          <Route exact path="/alumni" element={<Alumni />} /> 
          <Route exact path="/contactUs" element={<ContactUs />} /> 
          <Route exact path="/TC" element={<TC />} /> 
          <Route exact path="/ParentPortal" element={<ParentPortal />} /> 
          <Route exact path="/ERPSoftware" element={<ERPSoftware />} /> 
          <Route exact path="/magazine" element={<Magazine />} /> 
          <Route exact path="/Magazine2024" element={<Magazine2024 />} /> 
          <Route exact path="/Magazine2023" element={<Magazine2023 />} /> 
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;